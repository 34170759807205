import React from 'react'
import { IChat } from '../types/models'
import { Popover, HoverAction, Tooltip, useLocalStorageState } from 'simple-core-ui'
import { IoIosMore } from 'react-icons/io'
import s from '../styles/AIChat.scss'
import {
  differenceInDays,
  subDays,
  startOfDay,
  endOfDay,
  format,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear
} from 'date-fns'

interface SidebarProps {
  chats: IChat[]
  selectedChat: string | undefined | null
  onSelectSession: (id: string) => void
  handleDeleteChat: (id: string) => void
  handleNewChat: () => void
  handleRenameChat: (id: string) => void
}

export interface ChatSection {
  label: string
  chats: IChat[]
  from: Date
  to: Date
}

const getDynamicSection = (chat: IChat): ChatSection => {
  const today = new Date()
  const lastUpdated = new Date(chat.last_message_date)
  const diff = differenceInDays(today, lastUpdated)

  if (diff > 30 && diff <= 365) {
    const month = format(lastUpdated, 'MMMM')
    return {
      label: month,
      from: startOfMonth(new Date(lastUpdated)),
      to: endOfMonth(new Date(lastUpdated)),
      chats: [chat]
    }
    // otherwise create a section for the year "2022"
  } else {
    const year = format(lastUpdated, 'yyyy')
    return {
      label: year,
      from: startOfYear(new Date(lastUpdated)),
      to: endOfYear(new Date(lastUpdated)),
      chats: [chat]
    }
  }
}

export const toSections = (sections: ChatSection[], chat: IChat): ChatSection[] => {
  const lastMessageDate = new Date(chat.last_message_date)
  let correctSection = sections.find(s => {
    return s.from <= lastMessageDate && s.to >= lastMessageDate
  })

  if (correctSection) {
    correctSection.chats.push(chat)
  } else {
    correctSection = getDynamicSection(chat)
    sections.push(correctSection)
  }
  return sections
}

export const getStaticSections = (): ChatSection[] => {
  return [
    {
      label: 'Today',
      from: startOfDay(new Date()),
      to: endOfDay(new Date()),
      chats: []
    },
    {
      label: 'Yesterday',
      from: startOfDay(subDays(new Date(), 1)),
      to: endOfDay(subDays(new Date(), 1)),
      chats: []
    },
    {
      label: 'Previous 7 Days',
      from: startOfDay(subDays(new Date(), 7)),
      to: startOfDay(subDays(new Date(), 1)),
      chats: []
    },
    {
      label: 'Previous 30 Days',
      from: startOfDay(subDays(new Date(), 30)),
      to: startOfDay(subDays(new Date(), 7)),
      chats: []
    }
  ]
}

const SidebarActions = ({
  collapsed,
  handleCollapse,
  handleNewChat
}: {
  collapsed: boolean
  handleCollapse: () => void
  handleNewChat: () => void
}) => (
  <p className={s['sidebar-actions']} style={{ background: collapsed ? 'transparent' : '#F5F8FE' }}>
    <Tooltip
      trigger={
        <span className={s['toggle-sidebar']} onClick={handleCollapse}>
          {collapsed ? <span>&gt;</span> : <span>&lt;</span>}
        </span>
      }
      content={collapsed ? 'Open Sidebar' : 'Close Sidebar'}
    />

    <span className={s['new-chat']} onClick={handleNewChat}>
      +
    </span>
  </p>
)

export const buildSections = (chats: IChat[]): ChatSection[] => {
  return chats
    .reduce(toSections, getStaticSections())
    .filter(s => !!s.chats.length)
    .sort((a, b) => b.from.getTime() - a.from.getTime())
}

const Sidebar: React.FC<SidebarProps> = ({
  chats,
  onSelectSession,
  selectedChat,
  handleNewChat,
  handleDeleteChat,
  handleRenameChat
}) => {
  const [collapsed, setCollapsed] = useLocalStorageState('false', 'ask-ai-collapsed')
  const collapsedBoolean = collapsed === 'true'

  const visibleSections: ChatSection[] = buildSections(chats)

  return (
    <>
      {collapsed ? (
        <SidebarActions
          collapsed={collapsedBoolean}
          handleCollapse={() => setCollapsed(String(!collapsedBoolean))}
          handleNewChat={handleNewChat}
        />
      ) : null}
      <div className={s['sidebar']} style={collapsedBoolean ? { display: 'none' } : {}}>
        <SidebarActions
          collapsed={collapsedBoolean}
          handleCollapse={() => setCollapsed(String(!collapsedBoolean))}
          handleNewChat={handleNewChat}
        />
        <ul>
          {visibleSections.map(({ chats, label }) => {
            return (
              <React.Fragment key={label}>
                <li key={label} className={s['time-category']}>
                  {label}
                </li>
                {chats.map(chat => (
                  <li
                    key={chat.id}
                    className={[s['sidebar-chat'], selectedChat === chat.id && s['selected']]
                      .filter(Boolean)
                      .join(' ')}
                    onClick={() => {
                      if (chat.id === null) return
                      onSelectSession(chat.id)
                    }}
                  >
                    <span className={s['chat-name']}>{chat.name}</span>
                    {chat.id !== null ? (
                      <Popover
                        trigger={
                          <HoverAction
                            hasNewDesign
                            icon={<IoIosMore />}
                            size="small"
                            className={''}
                          />
                        }
                        triggerClassName={''}
                        actions={[
                          {
                            name: 'delete',
                            text: 'Delete',
                            onClick: () => {
                              if (chat.id === null) return
                              handleDeleteChat(chat.id)
                            }
                          },
                          {
                            name: 'rename',
                            text: 'Rename',
                            onClick: () => {
                              if (chat.id === null) return
                              handleRenameChat(chat.id)
                            }
                          }
                        ]}
                        border
                      />
                    ) : null}
                  </li>
                ))}
                <div style={{ marginBottom: 36 }} />
              </React.Fragment>
            )
          })}
        </ul>
      </div>
    </>
  )
}

export default Sidebar
