import { sortBy } from 'lodash'
import { makeGetRequest } from 'utils/api'

interface Vendor {
  id: string
  [key: string]: any
}

export const getVendorProperty = (list: Vendor[], id: string, property: string) => {
  const vendor = list.find(l => l.id === id)
  return vendor ? vendor[property] : ''
}

export const getCountries = async () => {
  const firstCountries = ['United States', 'Canada', 'United Kingdom']

  const url = '/invoices/vendors/countries'
  const response = await makeGetRequest(url)

  const allCountries = sortBy(sortBy(response.country_names, 'name'), (item, index) => {
    const sortIndex = firstCountries.indexOf(item.name)
    return sortIndex === -1 ? index : sortIndex
  }).map(c => ({ value: c.code, label: c.name }))

  return allCountries
}

export const handleAlphaNumericInput = (value: string) => {
  return value.replace(/[^a-zA-Z0-9\s]/g, '')
}

export const handleLettersOnlyInput = (value: string) => {
  return value.replace(/[^a-zA-Z\s]/g, '')
}
