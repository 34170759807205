import React from 'react'

interface Props {
  style?: React.CSSProperties
}

const WarningIcon = ({ style }: Props) => (
  <svg
    style={style}
    width="19"
    height="16"
    viewBox="0 0 19 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1586 11.493L11.3426 2.04498C10.9589 1.39724 10.262 1 9.50911 1C8.75626 1 8.05928 1.39724 7.67561 2.04498L1.85861 11.493C1.41876 12.1817 1.38094 13.0531 1.75947 13.7773C2.13801 14.5016 2.87505 14.968 3.69161 15H15.3256C16.1422 14.968 16.8792 14.5016 17.2577 13.7773C17.6363 13.0531 17.5985 12.1817 17.1586 11.493Z"
      stroke="#D4506A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9.50854 9V4" stroke="#D4506A" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M9.50854 12V11" stroke="#D4506A" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
)

export default WarningIcon
