import { useState, useEffect } from 'react'

const useLocalStorageState = (initialLocalStorageValue: string, localStorageKey: string) => {
  const initialValue: string = window.localStorage.getItem(localStorageKey) || ''

  const [value, setValue] = useState(initialValue ?? initialLocalStorageValue)

  useEffect(() => {
    window.localStorage.setItem(localStorageKey, value)
  }, [value])

  return [value, setValue] as const
}

export default useLocalStorageState
