import { call, put, takeLatest } from 'redux-saga/effects'
import APP_ACT from 'app/actions'
import ACT from './actions'

import { makePostRequest, makeFormDataPost, makePutRequest } from 'utils/api'
import { serializeParams, serializeVendorBulkData } from './list/serializers'
import { serializeVendorList } from './serializers'

function* vendorListFetch(action) {
  const { params } = action.payload

  const serialized = serializeParams(params)

  try {
    const response = yield makePostRequest(
      `/invoices/v2/vendors/${params.category.toLowerCase()}/`,
      serialized
    )
    yield put({
      type: ACT.VENDOR_LIST_FETCH_SUCCESS,
      loadingLock: 'off',
      payload: {
        vendors: serializeVendorList(response),
        params: {
          ...params
        }
      }
    })
  } catch (error) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      loadingLock: 'off',
      payload: {
        title: 'Error',
        message: 'There was an issue fetching vendor list.',
        level: 'error'
      }
    })
  }
}

function* notifyError(message) {
  yield put({
    type: APP_ACT.PUSH_NOTIFICATION,
    payload: {
      title: 'Error',
      level: 'error',
      message
    },
    loadingLock: 'off'
  })
}

function* vendorSummaryFetch(action) {
  const { params } = action.payload

  const serialized = serializeParams(params)

  try {
    const response = yield makePostRequest(`/invoices/v2/vendors/summary/`, serialized)
    yield put({
      type: ACT.VENDOR_SUMMARY_FETCH_SUCCESS,
      loadingLock: 'off',
      payload: response
    })
  } catch (error) {
    if (error.response.status === 403) {
      window.location.href = '/v2/vendor/list'
      return
    }
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      loadingLock: 'off',
      payload: {
        title: 'Error',
        message: 'There was an issue fetching vendor list.',
        level: 'error'
      }
    })
    yield notifyError('There was an issue fetching vendor list')
  }
}

export function* addNewVendor(action) {
  const {
    vendorName,
    vendorContactEmail,
    vendorContactPhone,
    vendorContactName,
    vendorUrl,
    requested,
    billingContactName,
    billingContactEmail,
    billingContactStreet1,
    billingContactStreet2,
    billingContactCity,
    billingContactState,
    billingContactZip,
    billingContactCountry = 'US'
  } = action.payload

  const body = {
    vendor_name: vendorName ? vendorName : null,
    vendor_email: vendorContactEmail,
    vendor_phone: vendorContactPhone ? vendorContactPhone : null,
    vendor_contact_name: vendorContactName ? vendorContactName : null,
    receive_from: vendorUrl ? vendorUrl : null,
    requested: requested,

    name: billingContactName,
    email: billingContactEmail,
    address_1: billingContactStreet1,
    address_2: billingContactStreet2 || null,
    city: billingContactCity,
    state: billingContactState,
    zip_code: billingContactZip,
    country: billingContactCountry.value
  }

  const addVendorUrl = '/invoices/vendors/ajax/add/'
  yield put({
    type: ACT.ADD_NEW_VENDOR_REQUEST,
    payload: { addVendorUrl, body, requested }
  })
}

export function* addNewVendorRequest(action) {
  try {
    const { addVendorUrl, body, requested } = action.payload
    const addVendorResponse = yield call(makeFormDataPost, addVendorUrl, body)
    if (addVendorResponse.vendor_id) {
      yield put({
        type: ACT.ADD_NEW_VENDOR_REQUEST_SUCCESS,
        loadingLock: 'off',
        payload: {}
      })
      if (!requested) {
        window.location = `${addVendorResponse.vendor_url}?fromNewVendorList=true`
      } else {
        window.location.reload()
      }
    }
  } catch (e) {
    const errors = e.response.data?.errors
    const errorMapping = {}

    if (errors) {
      Object.keys(errors).forEach(field => {
        errorMapping[field] = errors[field][0]
      })
    }

    yield put({
      type: ACT.UPDATE_ADD_VENDOR_FORM,
      payload: {
        errors: errorMapping
      }
    })

    yield notifyError('There was an issue creating new vendor')
  }
}

function* updateVendor(action) {
  const { id, params, listParams } = action.payload
  try {
    const serializedParam = params.vendor_lead ? serializeVendorBulkData(params) : params
    yield makePutRequest(`/invoices/v2/vendors/${id}/`, serializedParam)
    yield put({
      type: ACT.UPDATE_VENDOR_SUCCESS,
      payload: { id, ...params }
    })
    yield put({
      type: ACT.VENDOR_SUMMARY_FETCH_REQUESTED,
      payload: { params: listParams }
    })
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        message: 'Vendor updated successfully',
        level: 'success'
      }
    })
  } catch (error) {
    yield notifyError('There was an issue updating vendor')
  }
}

const vendorSagas = [
  takeLatest(ACT.VENDOR_LIST_FETCH_REQUESTED, vendorListFetch),
  takeLatest(ACT.VENDOR_SUMMARY_FETCH_REQUESTED, vendorSummaryFetch),
  takeLatest(ACT.ADD_NEW_VENDOR_REQUESTED, addNewVendor),
  takeLatest(ACT.ADD_NEW_VENDOR_REQUEST, addNewVendorRequest),
  takeLatest(ACT.UPDATE_VENDOR_REQUESTED, updateVendor)
]

export default vendorSagas
