import s from './VendorBilledForCg.scss'
import { KeyValuePairs } from 'simple-core-ui'
import { makeGetRequest, makePostRequest } from 'utils/api'
import { useEffect, useState } from 'react'
import EditableContainerModal from './EditableContainerModal'
import { getCountries, handleAlphaNumericInput, handleLettersOnlyInput } from 'vendors/utils'
import { BILLING_CONTACT_ATTRIBUTES } from 'vendors/constants'
import { AxiosError } from 'axios'

interface APIError {
  error?: Record<string, string[]>
}

const vendorId = window.serverContext.get('object_id')

const getLabel = (required: boolean, label: string) => {
  return required ? (
    <>
      {label}
      <span style={{ color: 'red', marginLeft: '2px' }}>*</span>
    </>
  ) : (
    label
  )
}

const VendorBilledForCg = () => {
  const [countries, setCountries] = useState<{ value: string; label: string }[]>([])
  const [contactDetails, setContactDetails] = useState<{ [key: string]: string }>({})

  const fetchBillingContact = async () => {
    try {
      const response = await makeGetRequest(`/invoices/vendors/${vendorId}/get_billing_contact/`)
      setContactDetails(response)
    } catch (error) {}
  }

  const fetchCountries = async () => {
    const countries = await getCountries()
    setCountries(countries)
  }

  useEffect(() => {
    fetchBillingContact()
    fetchCountries()
  }, [])

  const allRequiredFields: string[] = BILLING_CONTACT_ATTRIBUTES.filter(
    attr => attr.key !== 'address_2'
  ).map(attr => attr.key)

  const isRequiredField = (key: string) => allRequiredFields.includes(key)

  const validateBillingContactField = (field_name: string, value: string) => {
    let error = ''
    const isEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    if ((isRequiredField(field_name) && !value) || !value.trim()) {
      error = `${BILLING_CONTACT_ATTRIBUTES.find(attr => attr.key === field_name)?.attr_name ||
        field_name} is Required.`
    }

    if (field_name === 'email') {
      if (value && !value.match(isEmail)) {
        error = 'Enter a valid email address.'
      }
    }

    return error
  }

  const updateAttributeValue = async (field_name: string, value: string) => {
    const error = field_name !== 'address_2' ? validateBillingContactField(field_name, value) : ''
    if (error) {
      return error
    }

    try {
      await makePostRequest(`/invoices/vendors/${vendorId}/update_billing_contact`, {
        [field_name]: value
      })
      setContactDetails(prevDetails => ({ ...prevDetails, [field_name]: value }))
    } catch (err) {
      const error = err as AxiosError<APIError>
      const errorData = error?.response?.data || {}

      const fieldError = errorData.error?.[field_name]?.[0]
      const errorMessage = fieldError || 'An unexpected error occurred'

      return errorMessage
    }
  }

  const getSelectedCountry = () => {
    return (
      countries.find(
        (country: { value: string; label: string }) => country.value === contactDetails['country']
      ) || { value: '', label: '' }
    )
  }

  return (
    <div className={s.billingContainer}>
      <span className={s.heading}>Vendor Billing Contact Details</span>
      <hr className={s.hrTag} />

      <KeyValuePairs
        pairs={[
          {
            key: getLabel(isRequiredField('name'), 'Name'),
            value: (
              <EditableContainerModal
                type="text"
                initialValue={contactDetails['name']}
                onSubmit={(value: string) => updateAttributeValue('name', value)}
                validateChange={handleLettersOnlyInput}
              />
            ),
            style: { marginBottom: '15px', width: '48%' },
            keyClass: s.billingKey
          },
          {
            key: getLabel(isRequiredField('email'), 'Email'),
            value: (
              <EditableContainerModal
                type="text"
                initialValue={contactDetails['email']}
                onSubmit={(value: string) => updateAttributeValue('email', value)}
              />
            ),
            style: { marginBottom: '15px', width: '48%' },
            keyClass: s.billingKey
          },
          {
            key: getLabel(isRequiredField('address_1'), 'Street Address 1'),
            value: (
              <EditableContainerModal
                type="text"
                initialValue={contactDetails['address_1']}
                onSubmit={(value: string) => updateAttributeValue('address_1', value)}
              />
            ),
            style: { marginBottom: '15px', width: '48%' },
            keyClass: s.billingKey
          },
          {
            key: getLabel(isRequiredField('address_2'), 'Street Address 2'),
            value: (
              <EditableContainerModal
                type="text"
                initialValue={contactDetails['address_2']}
                onSubmit={(value: string) => updateAttributeValue('address_2', value)}
              />
            ),
            style: { marginBottom: '15px', width: '48%' },
            keyClass: s.billingKey
          },
          {
            key: getLabel(isRequiredField('city'), 'City'),
            value: (
              <EditableContainerModal
                type="text"
                initialValue={contactDetails['city']}
                onSubmit={(value: string) => updateAttributeValue('city', value)}
                validateChange={handleAlphaNumericInput}
              />
            ),
            style: { marginBottom: '15px', width: '48%' },
            keyClass: s.billingKey
          },
          {
            key: getLabel(isRequiredField('state'), 'State'),
            value: (
              <EditableContainerModal
                type="text"
                initialValue={contactDetails['state']}
                onSubmit={(value: string) => updateAttributeValue('state', value)}
                validateChange={handleAlphaNumericInput}
              />
            ),
            style: { marginBottom: '15px', width: '48%' },
            keyClass: s.billingKey
          },
          {
            key: getLabel(isRequiredField('zip_code'), 'Zip Code'),
            value: (
              <EditableContainerModal
                type="text"
                initialValue={contactDetails['zip_code']}
                onSubmit={(value: string) => updateAttributeValue('zip_code', value)}
              />
            ),
            style: { marginBottom: '15px', width: '48%' },
            keyClass: s.billingKey
          },
          {
            key: getLabel(isRequiredField('country'), 'Country'),
            value: (
              <EditableContainerModal
                type="text"
                initialValue={getSelectedCountry()?.label}
                optionValue={getSelectedCountry()}
                onSubmit={(value: string) => updateAttributeValue('country', value)}
                options={countries}
              />
            ),
            keyClass: s.billingKey,
            style: { width: '48%' }
          }
        ]}
      />
    </div>
  )
}

export default VendorBilledForCg
