export const SURVEY_QUESTIONS = {
  overall_satisfaction: 'What is your overall satisfaction?',
  expertise: 'Do they have expertise in this matter?',
  responsiveness: 'Are they responsive?',
  business_goals: 'Do they understand the business goals?',
  accurate_estimates: 'Are their work estimates accurate?',
  efficiency: 'Do they work efficiently?'
}

export const BILLING_CONTACT_ATTRIBUTES = [
  { key: 'name', attr_name: 'Name' },
  { key: 'email', attr_name: 'Email' },
  { key: 'address_1', attr_name: 'Street Address 1' },
  { key: 'address_2', attr_name: 'Street Address 2' },
  { key: 'city', attr_name: 'City' },
  { key: 'state', attr_name: 'State' },
  { key: 'zip_code', attr_name: 'Zip Code' },
  { key: 'country', attr_name: 'Country' }
]
