import { useEffect, useRef, useState } from 'react'
import { FaArrowDown } from 'react-icons/fa'
import s from '../styles/AIChat.scss'

export const useScrollArrow = () => {
  const ref = useRef<HTMLDivElement>(null)
  const [showScrollToBottom, setShowScrollToBottom] = useState(false)

  const scrollToBottom = () => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight
    }
  }

  const handleScroll = () => {
    if (ref.current) {
      const { scrollTop, scrollHeight, clientHeight } = ref.current
      const isAtBottom = scrollHeight - scrollTop - clientHeight <= 50
      setShowScrollToBottom(!isAtBottom)
    }
  }

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (ref.current) {
        ref.current.removeEventListener('scroll', handleScroll)
      }
    }
  }, [ref.current])

  return { ref, showScrollToBottom, scrollToBottom }
}

export const ScrollArrow = ({ scrollToBottom }: { scrollToBottom: () => void }) => {
  return (
    <button onClick={scrollToBottom} className={s['scroll-to-bottom']}>
      <FaArrowDown />
    </button>
  )
}
