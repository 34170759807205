import classNames from 'classnames'

import { Spinner } from 'simple-core-ui'
import s from './Input.scss'

const Input = ({
  id,
  type,
  value,
  onChange,
  placeholder,
  className,
  lowerCase,
  onClick,
  disabled,
  isLoading,
  onFocus,
  min,
  max,
  testid,
  ariaLabel,
  onKeyPress,
  errorMessage = ''
}) =>
  type === 'button' ? (
    <button
      id={id}
      onClick={onClick}
      className={classNames(s.base, { [className]: className })}
      disabled={disabled}
      data-testid={testid}
    >
      {value}
    </button>
  ) : (
    <div>
      <div className={classNames(s.container, { [s.errorInput]: errorMessage })}>
        <input
          aria-label={ariaLabel}
          id={id}
          data-testid={testid}
          type={type}
          value={value || ''}
          min={min}
          max={max}
          placeholder={placeholder}
          onChange={e => {
            const value = (lowerCase && e.target.value.toLowerCase()) || e.target.value
            onChange(value)
          }}
          onClick={() => onClick && onClick()}
          className={classNames(s.base, {
            [className]: className,
            [s.spinnerInput]: isLoading
          })}
          disabled={disabled}
          onFocus={onFocus}
          onKeyPress={onKeyPress}
          style={errorMessage ? { border: 'none' } : {}}
        />
        {isLoading && <Spinner />}
      </div>
      {errorMessage && <span className={s.errorMessage}>{errorMessage}</span>}
    </div>
  )

export default Input
