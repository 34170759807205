import { ReactAIChat, ReactAIChatProvider, AIStarsIcon } from 'simple-core-ui'
import s from './AIChatContainer.scss'

function AskAIContainer() {
  return (
    <div>
      <div className={s.header}>
        <span style={{ fontSize: '18px', fontWeight: 700 }}>
          AskAI <AIStarsIcon style={{ width: '20px', position: 'relative', top: '3px' }} />
        </span>
      </div>

      <ReactAIChatProvider
        initialState={{
          data: {
            urls: {
              examplePrompts: 'https://example.com',
              chatDetail: '/reports/chats/<id>/',
              chatList: '/reports/chats/',
              submitMessage: '/reports/chats/',
              createChat: '/reports/chats/',
              deleteChat: '/reports/chats/<id>',
              updateChat: '/reports/chats/<id>/'
            },
            user: {
              firstName: window.credentials.user.firstName,
              lastName: window.credentials.user.lastName
            }
          }
        }}
      >
        <ReactAIChat />
      </ReactAIChatProvider>
    </div>
  )
}

export default AskAIContainer
