import s from './CheckboxList.scss'
import { FaSearch } from 'react-icons/fa'
import {
  Button,
  CheckboxContainer,
  Ellipsis,
  LoadingSkeleton,
  sortAlphabeticallyByProperty
} from 'simple-core-ui'
import { useState, useEffect, ReactNode, CSSProperties } from 'react'
import { makeGetRequest } from 'utils/api'
import { useDispatch } from 'react-redux'
import { toItems } from './serializers'
import { Option } from './types'
import debounce from 'debounce-promise'
import ReactTooltip from 'react-tooltip'
import cn from 'classnames'
import InfiniteScroll from 'react-infinite-scroll-component'
import uniqBy from 'lodash/unionBy'

interface Props {
  togglePopper?: () => void
  value: Option[]
  onConfirm: (values: Option[]) => void
  isPaginated?: boolean
  serialiser?: (data: any) => Option[]
  secondaryColumn?: string
  secondaryColumnPosition?: 'below' | 'right'
  firstPageIndex?: number
  classes?: Partial<{
    scrollableDiv: string
  }>
  secondaryAction?: {
    element: ReactNode
    onClick: () => void
    parentStyle?: CSSProperties
  }
  searchTermParam?: string
  pageParam?: string
  isCg?: boolean
  searchPlaceholder?: string
  sortSelection?: boolean
  sortOptions?: boolean
}

interface WithOptions extends Props {
  url: string
  options?: never
}
interface WithUrl extends Props {
  url?: never
  options?: { id: number | string; name: string }[]
}

const PAGE_SIZE = 20

const CheckboxList = ({
  togglePopper,
  onConfirm,
  value,
  url,
  isPaginated,
  serialiser,
  options,
  secondaryColumn,
  secondaryColumnPosition = 'right',
  firstPageIndex = 0,
  classes,
  secondaryAction,
  pageParam = 'page_number',
  searchTermParam = 'search',
  isCg,
  searchPlaceholder = 'Search',
  sortSelection = true,
  sortOptions = true
}: WithOptions | WithUrl) => {
  const [items, setItems] = useState<Option[]>([])
  const [originalItems, setOriginalItems] = useState<Option[]>([])
  const [selectedItems, setselectedItems] = useState<Option[]>(value)
  const dispatch = useDispatch()
  const [page, setPage] = useState(firstPageIndex)
  const [hasMore, setHasMore] = useState(true)

  const serialize = serialiser || toItems

  const fetchItems = async (search?: string, firstPage?: boolean) => {
    let localPage = firstPage ? firstPageIndex : page
    let localHasMore = hasMore
    let localItems = selectedItems

    if (!url) {
      if (options) {
        setItems(serialize(options))
        setOriginalItems(serialize(options))
      }
      return
    }
    try {
      const getResponse = async () => {
        const response = await makeGetRequest(url, {
          params: {
            [pageParam]: localPage,
            page_size: PAGE_SIZE,
            [searchTermParam]: search
          }
        })
        return response
      }

      if (!isPaginated) {
        const response = await makeGetRequest(url)
        setItems(serialize(response))
        setOriginalItems(serialize(response))
      } else {
        if (firstPage) {
          if (search) {
            const res = await getResponse()
            setItems(serialize(res.rows || res))
            setPage(1)
            setHasMore(res.more)
            return
          } else {
            localPage = firstPageIndex
            localHasMore = true
          }
        }

        while (localHasMore) {
          let response
          try {
            response = await getResponse()
            localHasMore = response.more || ('next_page' in response && response.next_page !== null)
          } catch (error) {
            dispatch({ type: 'API_ERROR', error })
            return {
              hasMore: false
            }
          }
          const newItems = serialize(response.rows || response)
          const filteredItems = newItems.filter(
            item =>
              !selectedItems.find(selectedItem => String(selectedItem.value) === String(item.value))
          )
          if (filteredItems.length > 0) {
            localItems = [...localItems, ...filteredItems]
            localPage += 1
            if (filteredItems.length === PAGE_SIZE) break
          } else {
            localPage += 1
          }
        }
        setItems(
          sortOptions
            ? sortAlphabeticallyByProperty(uniqBy([...items, ...localItems], 'value'), 'label')
            : uniqBy([...items, ...localItems], 'value')
        )
        setPage(localPage)
        setHasMore(localHasMore)
      }
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  useEffect(
    () => {
      if (isPaginated) {
        if (
          items.filter(u => !selectedItems.find(su => String(u.value) === String(su.value)))
            .length === 0
        ) {
          fetchItems()
        }
      } else {
        fetchItems()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    isPaginated ? [selectedItems] : []
  )

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [items, selectedItems])

  const debounceOnChange = debounce((value: string) => {
    if (isPaginated) {
      fetchItems(value, true)
    } else {
      setItems(
        originalItems.filter(i => {
          if (!value) return true
          return i.label.toLowerCase().includes(value.toLowerCase())
        })
      )
    }
  }, 500)

  const toggleCheck = (option: Option) => {
    const arr = [...selectedItems]
    const index = arr.findIndex(e => String(e.value) === String(option.value))
    if (index !== -1) {
      arr.splice(index, 1)
      setselectedItems(arr)
    } else {
      setselectedItems(
        sortSelection ? sortAlphabeticallyByProperty([...arr, option], 'label') : [...arr, option]
      )
    }
  }

  return (
    <div className={s.container}>
      <div>
        <div className={s.searchBoxContainer}>
          <FaSearch style={{ color: '#b3b3b3', fontSize: 18 }} />
          <input
            onChange={e => {
              const { value } = e.target
              debounceOnChange(value)
            }}
            className={s.inputCheckbox}
            placeholder={searchPlaceholder}
          />
        </div>
        <div className={s.selectedItemsWrapper}>
          {selectedItems.map(option => {
            let Suffix = null
            if (option.labelSuffix) {
              Suffix = option.labelSuffix.tag
            }
            return (
              <div className={s.checkboxOption} key={option.value}>
                <CheckboxContainer
                  styles={{
                    borderColor: '#c4c4c4',
                    border: '1px solid #c4c4c4',
                    borderRadius: '4px',
                    marginTop: 2
                  }}
                  hasGreyBorder
                  size="sm"
                  isChecked={
                    selectedItems
                      ? !!selectedItems.find(el => String(el.value) === String(option.value))
                      : false
                  }
                  cb={() => toggleCheck(option)}
                />
                <div
                  className={cn(s.option, {
                    [s.secondaryColumnBelow]: secondaryColumnPosition === 'below'
                  })}
                >
                  <label className={s.checkboxLabel}>
                    <Ellipsis width={250} lines={2} className={s.ellipsisContainer}>
                      {option.label}
                    </Ellipsis>
                    {Suffix && (
                      <Suffix style={option.labelSuffix.style}>{option.labelSuffix.content}</Suffix>
                    )}
                  </label>
                  {secondaryColumn && (
                    <span className={s.secondaryColumn}>{option[secondaryColumn]}</span>
                  )}
                </div>
              </div>
            )
          })}
        </div>

        {selectedItems.length > 0 && <div className={s.divider} />}

        {isPaginated ? (
          <div id="scrollableDiv" className={s.scrollableDiv}>
            <InfiniteScroll
              dataLength={items.length}
              next={fetchItems}
              hasMore={hasMore}
              scrollableTarget="scrollableDiv"
              loader={
                <>
                  {Array(5)
                    .fill(0)
                    .map((_, i) => (
                      <section
                        key={i}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: '1em',
                          paddingBottom: 0
                        }}
                      >
                        {/* @ts-expect-error */}
                        <LoadingSkeleton height={20} width={365} />
                      </section>
                    ))}
                </>
              }
            >
              {items
                .filter(u => !selectedItems.find(su => String(u.value) === String(su.value)))
                .map(option => {
                  let Suffix = null
                  if (option.labelSuffix) {
                    Suffix = option.labelSuffix.tag
                  }
                  return (
                    <div className={s.checkboxOption} key={option.value}>
                      <CheckboxContainer
                        styles={{
                          borderColor: '#c4c4c4',
                          border: '1px solid #c4c4c4',
                          borderRadius: '4px',
                          marginTop: 2
                        }}
                        hasGreyBorder
                        size="sm"
                        isChecked={
                          selectedItems
                            ? !!selectedItems.find(el => String(el.value) === String(option.value))
                            : false
                        }
                        cb={() => toggleCheck(option)}
                      />
                      <div
                        className={cn(s.option, {
                          [s.secondaryColumnBelow]: secondaryColumnPosition === 'below'
                        })}
                      >
                        <label className={s.checkboxLabel}>
                          <Ellipsis width={250} lines={2} className={s.ellipsisContainer}>
                            {option.label}
                          </Ellipsis>
                          {Suffix && (
                            <Suffix style={option.labelSuffix.style}>
                              {option.labelSuffix.content}
                            </Suffix>
                          )}
                        </label>
                        {secondaryColumn && (
                          <span className={s.secondaryColumn}>{option[secondaryColumn]}</span>
                        )}
                      </div>
                    </div>
                  )
                })}
            </InfiniteScroll>
          </div>
        ) : (
          <div className={cn(s.scrollableDiv, classes?.scrollableDiv)}>
            {items
              .filter(u => !selectedItems.find(su => String(u.value) === String(su.value)))
              .map(option => {
                let Suffix = null
                if (option.labelSuffix) {
                  Suffix = option.labelSuffix.tag
                }
                return (
                  <div className={s.checkboxOption} key={option.value}>
                    <CheckboxContainer
                      styles={{
                        borderColor: '#c4c4c4',
                        border: '1px solid #c4c4c4',
                        borderRadius: '4px',
                        marginTop: 2
                      }}
                      hasGreyBorder
                      size="sm"
                      isChecked={
                        selectedItems
                          ? !!selectedItems.find(el => String(el.value) === String(option.value))
                          : false
                      }
                      cb={() => toggleCheck(option)}
                    />
                    <div
                      className={cn(s.option, {
                        [s.secondaryColumnBelow]: secondaryColumnPosition === 'below'
                      })}
                    >
                      <label className={s.checkboxLabel}>
                        <Ellipsis width={250} lines={2} className={s.ellipsisContainer}>
                          {option.label}
                        </Ellipsis>
                        {Suffix && (
                          <Suffix style={option.labelSuffix.style}>
                            {option.labelSuffix.content}
                          </Suffix>
                        )}
                      </label>
                      {secondaryColumn && (
                        <span className={s.secondaryColumn}>{option[secondaryColumn]}</span>
                      )}
                    </div>
                  </div>
                )
              })}
          </div>
        )}

        <div
          className={s.footer}
          style={secondaryAction ? { justifyContent: 'space-between' } : {}}
        >
          {secondaryAction && (
            <div
              style={secondaryAction.parentStyle}
              onClick={() => {
                secondaryAction.onClick()
                togglePopper?.()
              }}
            >
              {secondaryAction.element}
            </div>
          )}
          <div className={s.footerButtons}>
            <Button
              hasNewDesign
              isPrimary
              isOutline
              onClick={() => {
                togglePopper?.()
              }}
              isCg={isCg}
            >
              Cancel
            </Button>
            <Button
              hasNewDesign
              onClick={() => {
                togglePopper?.()
                onConfirm?.(selectedItems)
              }}
              isPrimary
              isCg={isCg}
              isFill={isCg}
              className={s.primaryButton}
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CheckboxList
