import { ApexOptions } from 'apexcharts'
import { ModifiedApexChartOptions } from './types/models'
import { format } from 'date-fns'

type SupportedChartTypes = 'line' | 'bar' | 'area' | 'pie'

const formatCurrency = (val: number, opts?: any): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
  }).format(val)
}

const formatString = (val: number, opts?: any): string => {
  return String(val)
}

const formatNumber = (val: number, opts?: any): string => {
  return val.toLocaleString('en-US')
}

const formatDate = (val: number, opts?: any): string => {
  return format(new Date(val), 'MM/DD/YYYY')
}

function buildFormatter(formatter: string | undefined) {
  switch (formatter) {
    case 'string':
      return formatString
    case 'number':
      return formatNumber
    case 'currency':
      return formatCurrency
    case 'datetime':
      return formatDate
    default:
      return formatString
  }
}

export function buildChartOptions(
  type: SupportedChartTypes,
  options: ModifiedApexChartOptions
): ApexOptions {
  const baseOptions = {
    chart: { ...options.chart },
    dataLabels: {
      enabled: false
    }
  }

  function buildLine() {
    return {
      ...baseOptions,
      tooltip: {
        ...options.tooltip,
        y: {
          ...options.tooltip?.y,
          formatter: buildFormatter(options.tooltip?.y?.formatter)
        }
      },
      yaxis: {
        ...options.yaxis,
        labels: {
          formatter: buildFormatter(options.yaxis?.labels?.formatter)
        }
      },
      xaxis: {
        ...options.xaxis,
        labels: { formatter: (val: string) => val }
      }
    }
  }

  function buildBar() {
    return {
      ...baseOptions,
      tooltip: {
        ...options.tooltip,
        y: {
          ...options.tooltip?.y,
          formatter: buildFormatter(options.tooltip?.y?.formatter)
        }
      },
      yaxis: {
        ...options.yaxis,
        labels: {
          formatter: buildFormatter(options.yaxis?.labels?.formatter)
        }
      },
      xaxis: {
        ...options.xaxis,
        labels: { formatter: (val: string) => val }
      }
    }
  }

  function buildArea() {
    return {
      ...baseOptions,
      tooltip: {
        ...options.tooltip,
        y: {
          ...options.tooltip?.y,
          formatter: buildFormatter(options.tooltip?.y?.formatter)
        }
      },
      yaxis: {
        ...options.yaxis,
        labels: {
          formatter: buildFormatter(options.yaxis?.labels?.formatter)
        }
      },
      xaxis: {
        ...options.xaxis,
        labels: { formatter: (val: string) => val }
      }
    }
  }

  function buildPie() {
    return {
      ...baseOptions,
      labels: options.labels,
      tooltip: {
        ...options.tooltip,
        y: {
          ...options.tooltip?.y,
          formatter: buildFormatter(options.tooltip?.y?.formatter)
        }
      }
    }
  }

  switch (type) {
    case 'line':
      return buildLine()
    case 'bar':
      return buildBar()
    case 'area':
      return buildArea()
    case 'pie':
      return buildPie()
    default:
      throw new Error('Invalid chart type')
  }
}
