import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import s from './VendorListHeader.scss'
import ACT from 'vendors/actions'
import { Modal, Button } from 'simple-core-ui'
import CreateVendorForm from '../../forms/CreateVendorForm'
import { serializeParams } from '../serializers'
import { makeBlobRequest } from 'utils/api'
import { validateBillingContact, validateNewVendor } from '../../forms/validators'
import queryString from 'query-string'
import { hasModule } from 'utils/helpers'
import { MODULE } from 'utils/constants'
import { BulkShareModal } from './BulkShareModal'

type Props = {
  selectedTab: string
  title: string
}

interface RootState {
  vendors: {
    createForm: {
      vendorUrl: string
      vendorContactEmail: string
      billingContactName: string
      billingContactEmail: string
      billingContactStreet1: string
      billingContactStreet2: string
      billingContactCity: string
      billingContactState: string
      billingContactZip: string
      billingContactCountry: string
    }
    vendorListParams: Record<string, unknown>
    vendorPermissions: Record<string, unknown>
    vendorListSummary: Record<string, unknown>
  }
}

const hasVendorBillingContactPermission = window.serverContext.get('data_for_react')
  ?.has_vendor_billing_contact_permission

const VendorListHeader = ({ selectedTab, title }: Props) => {
  const dispatch = useDispatch()
  const createForm = useSelector((state: RootState) => state.vendors.createForm)
  const params = useSelector((state: RootState) => state.vendors.vendorListParams)
  const permissions = useSelector((state: RootState) => state.vendors.vendorPermissions)
  const statusCounts = useSelector((state: RootState) => state.vendors.vendorListSummary)

  const [isModalVisible, setModalVisible] = useState(false)
  const [isBulkShareModalVisible, setBulkShareModalVisible] = useState(false)

  useEffect(() => {
    const showVendorModal = queryString.parse(window.location.search).new
    showVendorModal ? setModalVisible(true) : null
  }, [])

  const addNewVendor = () => {
    const { isError: isVendorError, errors: vendorErrors } = validateNewVendor(createForm)

    let isBillingContactError = false
    let billingContactErrors = {}

    if (hasVendorBillingContactPermission) {
      const billingContactValidation = validateBillingContact(createForm)
      isBillingContactError = billingContactValidation.isError
      billingContactErrors = billingContactValidation.errors
    }

    const combinedErrors = {
      ...vendorErrors,
      ...billingContactErrors
    }

    if (isVendorError || isBillingContactError) {
      dispatch({
        type: ACT.UPDATE_ADD_VENDOR_FORM,
        payload: {
          errors: combinedErrors
        }
      })
    } else {
      dispatch({
        type: ACT.ADD_NEW_VENDOR_REQUESTED,
        loadingLock: 'on',
        payload: {
          ...createForm,
          requested: false
        }
      })
      setModalVisible(false)
    }
  }

  const handleDownload = async () => {
    const baseParams = serializeParams(params)
    const downloadUrl = `/invoices/v2/vendors/${selectedTab.toLowerCase()}/?export=true`
    try {
      const data = await makeBlobRequest({
        url: downloadUrl,
        method: 'POST',
        data: baseParams
      })
      if (data.size) {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(data)
        link.download = 'Vendor Information.xlsx'
        link.click()
      }
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }

  const navigateTo = (url: string) => {
    window.location.href = url
  }

  return (
    <div className={s.header}>
      <h2>{title}</h2>
      <div className={s.rightActions}>
        <Button isSecondary onClick={handleDownload}>
          Download
        </Button>
        {permissions.enableManageVendorGroups ? (
          <Button
            isSecondary
            onClick={() => navigateTo('/invoices/vendors/groups/?fromNewVendorList=true')}
          >
            Manage Vendor Groups
          </Button>
        ) : null}
        {hasModule(MODULE.BULK_FILE_SHARING_WITH_VENDORS) && (
          <Button
            isSecondary
            isDisabled={statusCounts.active === 0}
            onClick={() => setBulkShareModalVisible(true)}
          >
            Bulk Share Files
          </Button>
        )}
        {permissions.enableEditVendor ? (
          <Button isPrimary onClick={() => setModalVisible(true)}>
            Add Vendor
          </Button>
        ) : null}
      </div>
      {isModalVisible && (
        <Modal
          title="Add Vendor"
          confirmText="Submit"
          cancelText="Cancel"
          size="sm"
          content={
            <CreateVendorForm
              hasVendorBillingContactPermission={hasVendorBillingContactPermission}
            />
          }
          isDisabled={createForm.vendorUrl?.length > 128}
          isVisible={isModalVisible}
          confirmCb={addNewVendor}
          cancelCb={() => setModalVisible(false)}
          testid="add-vendor-modal"
        />
      )}
      {isBulkShareModalVisible && (
        <BulkShareModal hideModal={() => setBulkShareModalVisible(false)} />
      )}
    </div>
  )
}

export default VendorListHeader
