import { useState, useMemo, useEffect, useCallback } from 'react'
import {
  Panel,
  Button,
  DataTableWrapper,
  useLoading,
  ModalContainer,
  Ellipsis
} from 'simple-core-ui'
import { ActionsPopover } from './ActionsPopover'
import ACT from 'accruals_vs_actuals/actions'
import { Filters } from './Filters'
import { useImmer } from 'use-immer'
import { openBlob } from 'utils/helpers'
import {
  makeGetRequest,
  makePostRequest,
  makePatchRequest,
  makeDeleteRequest,
  makeBlobRequest
} from 'utils/api'
import { useDispatch } from 'react-redux'
import { AddValueModal } from './AddValueModal'
import { WarningModal } from './WarningModal'
import swal from 'sweetalert'
import moment from 'moment'
import { AxiosError } from 'axios'

const initialParams = {
  pageSize: 10,
  ordering: { columnKey: 'received_date', isDesc: true },
  search: '',
  filters: {},
  page: 1
}

const initialValue = {
  short_name: '',
  description: '',
  code: ''
}

function valuesHasKey(values = [], key) {
  const firstResult = values.length > 0 ? values[0] : {}
  return firstResult.hasOwnProperty(key)
}

const AttributeValues = ({
  attribute,
  legalEntityManagement,
  matterGroupManagement,
  fetchAttribute
}) => {
  const [params, setParams] = useImmer(initialParams)
  const [values, setValues] = useImmer({ results: [], count: 0, needsEmail: false })
  const [isLoading, withLoadingLocks] = useLoading()
  const dispatch = useDispatch()
  const [isAddValueModalVisible, setIsAddValueModalVisible] = useState(false)
  const [isWarningModalVisible, setIsWarningModalVisible] = useState(false)
  const [newValue, setNewValue] = useImmer(initialValue)
  const [matters, setMatters] = useState([])
  const [templates, setTemplates] = useState([])
  const [errors, setErrors] = useState({})

  const fetchAttributeValues = useCallback(async () => {
    try {
      const status = params.filters.status
      const requestParams = {
        size: params.pageSize,
        page: params.page,
        ordering: params.ordering.columnKey,
        search: params.search,
        status: !status || status.value === 'all' ? '' : status.label
      }
      if (params.ordering.isDesc) requestParams.ordering = `-${requestParams.ordering}`
      const data = await withLoadingLocks(
        makeGetRequest(`/manage/api/matter_attributes/${attribute.id}/values_list`, {
          params: requestParams
        })
      )

      setValues(data)
      fetchScopeCount(data.results.map(result => result.id))
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error: {
          ...error,
          errorTitle: 'Error'
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, attribute.id])

  const fetchScopeCount = async ids => {
    try {
      if (ids.length === 0) return
      const data = await withLoadingLocks(
        makeGetRequest(`/manage/api/matter_attributes/${attribute.id}/values_list/count_scopes`, {
          params: {
            ids
          }
        })
      )

      setValues(draft => {
        for (const result of draft.results) {
          Object.assign(result, data[result.id])
        }
      })
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error: { ...error, errorTitle: 'Error' }
      })
    }
  }

  useEffect(() => {
    fetchAttributeValues()
  }, [fetchAttributeValues])

  const columns = useMemo(() => {
    return [
      {
        columnKey: 'short_name',
        content: <Ellipsis width={200}>{attribute.name}</Ellipsis>,
        isSortable: true,
        isFilterable: true,
        render: (cell, row) => {
          if (!cell.content.length) return '----'
          return <Ellipsis width={200}>{cell.content}</Ellipsis>
        }
      },
      {
        columnKey: 'description',
        content: (
          <>
            <Ellipsis width={200}>{attribute.name}</Ellipsis> Description
          </>
        ),
        isSortable: true,
        isFilterable: true,
        render: (cell, row) => {
          if (!cell.content.length) return '----'
          return <Ellipsis width={200}>{cell.content}</Ellipsis>
        }
      },
      {
        columnKey: 'code',
        content: 'Code',
        isSortable: true,
        isFilterable: true
      },
      {
        columnKey: 'id',
        content: 'ID',
        isSortable: true,
        isFilterable: true
      },
      {
        columnKey: 'practice_areas',
        content: <Ellipsis width={200}>{`Applicable ${matterGroupManagement[1]}`}</Ellipsis>,
        isSortable: true,
        isFilterable: true,
        render: (cell, row) => {
          if (!cell.content.length) return '----'
          return <Ellipsis width={200}>{cell.content.map(p => p.short_name).join(', ')}</Ellipsis>
        }
      },
      {
        columnKey: 'legal_entities',
        content: <Ellipsis width={200}>{`Applicable ${legalEntityManagement[1]}`}</Ellipsis>,
        isSortable: true,
        isFilterable: true,
        render: (cell, row) => {
          if (!cell.content.length) return '----'
          return (
            <Ellipsis width={200}>{cell.content.map(l => l.entity_short_name).join(', ')}</Ellipsis>
          )
        }
      },
      // Ternary structure checks the backend response in the values collection
      // to see if there is a key as noted below.  If there is a key matching the
      // columnKey provided it will display the column, if not the column is not rendered.
      ...(valuesHasKey(values.results, 'invoice_count')
        ? [
            {
              columnKey: 'invoice_count',
              content: 'Invoices',
              isSortable: false,
              style: { textAlign: 'center' },
              render: (cell, row) => {
                if (isNaN(cell.content)) return cell.content
                return (
                  <a
                    href={`/reports/invoices/?invoice_status=all&display_currency=native&\
received_start_date=${moment(new Date())
                      .subtract(10, 'years')
                      .format('MM-DD-YYYY')}&\
received_end_date=${moment(new Date()).format('MM-DD-YYYY')}&\
other_attribute=${attribute.id}&other_attribute_list_value=${row.id}`}
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    {cell.content}
                  </a>
                )
              }
            }
          ]
        : []),
      ...(valuesHasKey(values.results, 'entity_count')
        ? [
            {
              columnKey: 'entity_count',
              content: <Ellipsis width={200}>{legalEntityManagement[1]}</Ellipsis>,
              isSortable: false,
              style: { textAlign: 'center' },
              render: (cell, row) => {
                if (isNaN(cell.content)) return cell.content
                return (
                  <a
                    href={`/manage/entities/?status=all&other_attribute=${attribute.id}&other_attribute_list_value=${row.id}`}
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    {cell.content}
                  </a>
                )
              }
            }
          ]
        : []),
      ...(valuesHasKey(values.results, 'matters_count')
        ? [
            {
              columnKey: 'matters_count',
              content: attribute.has_enhancements ? 'Matters' : 'Matter Count',
              isSortable: false,
              style: { textAlign: 'center' },
              render: (cell, row) => {
                if (isNaN(cell.content) || !attribute.has_enhancements) return cell.content
                return (
                  <a
                    href={`/v2/matters/list#status=open:Open&status=draft:Draft&status=closed:Closed&other_attribute=${attribute.id}&other_attribute_list_value=${row.id}`}
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    {cell.content}
                  </a>
                )
              }
            }
          ]
        : []),
      ...(valuesHasKey(values.results, 'po_count')
        ? [
            {
              columnKey: 'po_count',
              content: 'Purchase Orders',
              isSortable: false,
              style: { textAlign: 'center' },
              render: (cell, row) => {
                if (isNaN(cell.content) || !window.credentials.purchaseOrdersEnabled)
                  return cell.content

                return (
                  <a
                    href={`/invoices/po/?status=all&other_attribute=${attribute.id}&other_attribute_list_value=${row.id}`}
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    {cell.content}
                  </a>
                )
              }
            }
          ]
        : []),
      ...(valuesHasKey(values.results, 'vendor_count')
        ? [
            {
              columnKey: 'vendor_count',
              content: 'Vendors',
              isSortable: false,
              style: { textAlign: 'center' },
              render: (cell, row) => {
                if (isNaN(cell.content)) return cell.content
                return (
                  <a
                    href={`/v2/vendor/list#category=All&other_attribute_list_value_attribute=${attribute.id}&other_attribute_list_value=${row.id}`}
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    {cell.content}
                  </a>
                )
              }
            }
          ]
        : []),
      {
        columnKey: 'status',
        content: 'Status',
        isSortable: true,
        style: { textAlign: 'center' }
      }
    ]
  }, [matterGroupManagement, legalEntityManagement, values, attribute])

  const results = useMemo(() => {
    const { results, count, needsEmail } = values
    return {
      columns,
      rows: results,
      totalEntries: count,
      filteredTotal: count,
      needsEmail: needsEmail
    }
  }, [columns, values])

  const editValue = rowId => {
    const selectedValue = values.results.find(r => r.id === rowId)
    setNewValue(() => selectedValue)
    setIsAddValueModalVisible(true)
  }

  const resetModal = () => {
    setNewValue(() => initialValue)
    setErrors({})
  }

  const deleteValue = async rowId => {
    const willDelete = await swal({
      title: 'Delete Value',
      text: 'Are you sure you want to delete this value?',
      buttons: ['Cancel', 'Yes'],
      icon: 'warning'
    })

    if (willDelete) {
      try {
        await makeDeleteRequest(`/manage/api/matter_attributes/values_list/${rowId}`)
        const arr = [...values.results]
        const index = arr.findIndex(r => r.id === rowId)
        arr.splice(index, 1)
        setValues(draft => {
          draft.count = draft.count - 1
          draft.results = arr
        })
      } catch (error) {
        dispatch({
          type: 'API_ERROR',
          error: { ...error, errorTitle: 'Error' }
        })
      }
    }
  }

  const renderCustomAction = row => (
    <ActionsPopover
      rowId={row.id}
      editValue={editValue}
      deleteValue={deleteValue}
      canDelete={row.can_delete}
    />
  )

  const updateTable = updatedParams => {
    setParams(() => ({ ...updatedParams, filters: params.filters }))
  }

  const onSearch = (value, filter) => {
    setParams(draft => {
      if (filter) {
        draft.filters[filter] = value
      } else {
        draft.filters.status = null
      }
    })
  }

  const addValue = async () => {
    const needToResetColumns = values.count === 0
    try {
      const result = await withLoadingLocks(
        makePostRequest(`/manage/api/matter_attributes/${attribute.id}/values_list`, {
          short_name: newValue.short_name,
          description: newValue.description,
          code: newValue.code
        })
      )
      if (needToResetColumns) {
        // reset the table with the correct columns if there are no records on Custom Attribute,
        // as they aren't calculated ahead of time.
        fetchAttribute()
      }
      setValues(draft => {
        draft.count = draft.count + 1
        draft.results = [...values.results, result]
      })
      setIsAddValueModalVisible(false)
      resetModal()
    } catch (error) {
      if (error instanceof AxiosError) {
        setErrors(error.response.data)
      } else {
        setIsAddValueModalVisible(false)
        resetModal()
        dispatch({
          type: 'API_ERROR',
          error: { ...error, errorTitle: 'Error' }
        })
      }
    }
  }

  const updateValue = async () => {
    try {
      const result = await withLoadingLocks(
        makePatchRequest(`/manage/api/matter_attributes/values_list/${newValue.id}`, {
          short_name: newValue.short_name,
          description: newValue.description,
          code: newValue.code,
          legal_entities: newValue.legal_entities?.map(l => ({ id: l.value || l.id })) ?? [],
          practice_areas: newValue.practice_areas?.map(p => ({ id: p.value || p.id })) ?? [],
          status: newValue.status.label
        })
      )
      setValues(draft => {
        const index = values.results.findIndex(r => r.id === result.id)
        draft.results[index] = result
      })
      setIsAddValueModalVisible(false)
      resetModal()
    } catch (error) {
      if (error instanceof AxiosError) {
        const { legal_entities, practice_areas } = error.response.data
        if (error.response.status === 400) {
          dispatch({
            type: 'PUSH_NOTIFICATION',
            payload: {
              title: `${practice_areas?.[0] ?? ''}; ${legal_entities?.[0] ?? ''}`,
              level: 'error'
            }
          })
        } else {
          setErrors(error.response.data)
        }
      } else {
        const { legal_entities, practice_areas } = error.response.data
        if (error.response.status === 400) {
          setMatters([
            ...(practice_areas?.matters ? practice_areas?.matters : []),
            ...(legal_entities?.matters ? legal_entities?.matters : [])
          ])
          setTemplates([
            ...(practice_areas?.templates ? practice_areas?.templates : []),
            ...(legal_entities?.templates ? legal_entities?.templates : [])
          ])
          setIsWarningModalVisible(true)
        } else {
          dispatch({
            type: 'API_ERROR',
            error: { ...error, errorTitle: 'Error' }
          })
        }
        setIsAddValueModalVisible(false)
        resetModal()
      }
    }
  }

  const handleDownload = (openModal, email, name) => async () => {
    try {
      const status = params.filters.status
      const requestParams = {
        export: true,
        search: params.search,
        status: !status || status.value === 'all' ? '' : status.label
      }
      const data = await makeBlobRequest({
        url: `/reports/custom/export_attribute_values/${attribute.id}`,
        method: 'GET',
        params: requestParams
      })

      if (email) {
        openModal()
      } else {
        openBlob(data, attribute.name + ' List.xlsx')
      }
    } catch (error) {
      dispatch({
        type: ACT.PUSH_NOTIFICATION,
        payload: {
          title: `Sorry, couldn't retrieve the XLSX for your request. Please try again with other filters.`,
          level: 'error'
        }
      })
    }
  }

  return (
    <Panel
      title={<Ellipsis width={600}>{`${attribute.name} List`}</Ellipsis>}
      rightActions={[
        <ModalContainer
          key={0}
          title="We are working on your download"
          content="You'll receive an email once your report is ready."
          confirmText="OK"
          // eslint-disable-next-line no-console
          confirmCb={() => console.log('Clicked close modal!')}
          size="sm"
        >
          {openModal => (
            <Button
              isSecondary
              onClick={handleDownload(openModal, results.needsEmail, attribute.name)}
            >
              {results.needsEmail ? 'Email list' : 'Download list'}
            </Button>
          )}
        </ModalContainer>,
        <Button key={1} isPrimary onClick={() => setIsAddValueModalVisible(true)}>
          Add Value
        </Button>
      ]}
    >
      <DataTableWrapper
        params={params}
        remotePagination
        tableHeight="500px"
        categories={[]}
        rows={results.rows}
        columns={results.columns}
        totalEntries={results.totalEntries}
        filteredTotal={results.filteredTotal}
        updateTable={updateTable}
        panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
        customAction={renderCustomAction}
        hasActions
        hasTooltip
        isLoading={isLoading}
        filters={<Filters filterParams={params.filters} onSearch={onSearch} />}
      />
      {isAddValueModalVisible && (
        <AddValueModal
          value={newValue}
          setValue={setNewValue}
          isAddValueModalVisible={isAddValueModalVisible}
          setIsAddValueModalVisible={setIsAddValueModalVisible}
          addValue={newValue.id ? updateValue : addValue}
          attributeName={attribute.name}
          resetModal={resetModal}
          matterGroupManagement={matterGroupManagement}
          legalEntityManagement={legalEntityManagement}
          errors={errors}
        />
      )}
      {isWarningModalVisible && (
        <WarningModal
          isWarningModalVisible={isWarningModalVisible}
          setIsWarningModalVisible={setIsWarningModalVisible}
          mattersLength={matters.length}
          setMatters={setMatters}
          templatesLength={templates.length}
          setTemplates={setTemplates}
          matterGroupManagement={matterGroupManagement}
          legalEntityManagement={legalEntityManagement}
        />
      )}
    </Panel>
  )
}

export default AttributeValues
