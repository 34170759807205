import { useContext, useMemo, useState } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { BsFillPersonCheckFill } from 'react-icons/bs'
import s from './EnhancedTimekeeepersContainer.scss'
import cn from 'classnames'
import { FaMoneyBill } from 'react-icons/fa'
import { DataTableWrapper, useLoading } from 'simple-core-ui'
import { Tabs } from './Tabs'
import { Cell } from './types'
import ThemeContext from 'context/ThemeContext'

const EnhancedTimekeepersContainer = () => {
  const [localState, setLocalState] = useState({
    params: {
      pageSize: 10,
      ordering: { columnKey: 'dueDate', isDesc: false },
      search: '',
      page: 1,
      category: 'pending'
    }
  })
  const { params } = localState

  const [view, setView] = useState('rates')

  const { state } = useContext(ThemeContext)
  const [selectedTab, setSelectedTab] = useState(params.category)
  const [totalEntries, setTotalEntries] = useState(0)
  const [pendingCount, setPendingCount] = useState(0)
  const [completedCount, setCompletedCount] = useState(0)
  const [allCount, setAllCount] = useState(0)

  const [isLoading, withLoadingLocksGetTasks] = useLoading()

  const columns = useMemo(() => {
    return [
      {
        columnKey: 'id',
        content: 'ID',
        isSortable: true,
        isFilterable: true,
        style: { maxWidth: '400px', position: 'relative' },
        render: (cell: Cell, row: any) => {
          return <></>
        }
      },
      {
        columnKey: 'timekeeper_name',
        content: 'Timekeeper',
        isSortable: true,
        isFilterable: true
      }
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  const onChangeTabCb = (tab: string) => {}

  const changeTab = (tab: string) => {
    setSelectedTab(tab)
    onChangeTabCb(tab)
  }

  return (
    <section className={s.container}>
      <section className={s.panel}>
        <a href="/v2/administration/">
          <IoIosArrowBack />
          {' Back to Administration'}
        </a>

        <div className={s.panelContainer}>
          <div className={s.innerPanel}>
            <div className={s.header}>
              <h2 className={s.title} data-testid="title">
                Timekeepers
              </h2>
              <div className={s.tabs}>
                <span
                  className={cn({ [s.selected]: view === 'rates' })}
                  onClick={() => {
                    setView('rates')
                  }}
                >
                  <FaMoneyBill /> Rate Review
                </span>
                <span
                  className={cn({ [s.selected]: view === 'list' })}
                  onClick={() => {
                    setView('list')
                  }}
                >
                  <BsFillPersonCheckFill /> Timekeepers List
                </span>
              </div>
            </div>
            <Tabs
              selectedTab={selectedTab}
              setSelectedTab={changeTab}
              pendingCount={pendingCount}
              completedCount={completedCount}
              allCount={allCount}
            />
            <DataTableWrapper
              isLoading={isLoading}
              remotePagination
              params={params}
              categories={[]}
              rows={[]}
              totalEntries={totalEntries}
              columns={columns}
            />
          </div>
        </div>
      </section>
    </section>
  )
}

export default EnhancedTimekeepersContainer
